.bm-item-list {
  margin-top: 0;
}

.menu-hover {
  color: _palette(fg-bold);
  font-size: 3.5em;
  padding-right: .1em;
  padding-top: .1em;
}

.menu-hover-open {
  padding-right: .2em;
  padding-top: .2em;
}

.menu-hover:hover {
  color: _palette(fg-light); /* This will make the icon turn grey on hover */
}

.hamburger-ul {
  display: block;

  li a h3 {
    border: 0;
    border-top: dotted 1px rgba(160, 160, 160, 0.3);
    margin: 1.5em 0 0 0;
    padding: 1.5em 0 0 0;
  }

  li a h3:hover {
    color: _palette(accent);
  }

  li {
    display: block !important;
  }

  h3 {
    font-size: 0.7em;
  }

  .index-li {
    border-top: none;
  }
}

@include breakpoint(medium) {
  
  .menu-hover {
    font-size: 12em; /* Doubling the original font-size for .menu-hover */
    padding-right: .05em;
    padding-top: .1em;
  }
  .menu-hover-open {
    padding-right: .2em;
    padding-top: .3em;
  }
  .hamburger-ul{
    padding-top: 7em;

    h3{
      font-size: 1em;
    }
  }
}
