///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* gallery-item */


.gallery-item {
  width: 100%;
  margin: 0 auto;

  img {
    width: 100%;
    display: block;
  }
}
