///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* WrapperV2 */
#wrapperV2 {
    @include vendor('display', 'flex');
    @include vendor('flex-direction', 'row');
    @include vendor('transition', 'opacity #{_duration(menu)} ease');
    margin: 0 auto;
    max-width: 100%;
    opacity: 1;
    padding: (_size(section-spacing) * .25);
    width: 90em;


    body.is-menu-visible & {
        opacity: 0.15;
    }

    @include breakpoint(xlarge) {
        padding: (_size(section-spacing) * .5);
    }

  //  @include breakpoint(large) {
   //     display: block;
  //  }

    @include breakpoint(small) {
        padding: (_size(section-spacing-small) * .1);
    }


  //  body.single & {
  //      display: block;
  //  }
}